import React from "react";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import PageWrapper from "components/PageWrapper";
import RichText from "components/RichText";
import log from "utils/log";
import * as siteTheme from "theme";
import * as heroClasses from "./hero.module.css";

export const query = graphql`
  query {
    descriptionImage: contentfulAsset(
      contentful_id: { eq: "4MO4JDc5UBW9bUV9oR5DaR" }
    ) {
      id
      fixed {
        src
      }
    }
    heroImage: contentfulAsset(contentful_id: { eq: "oQuNRArNRSkA9ZrkakYLh" }) {
      id
      fluid {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    faqs: allContentfulFaq {
      edges {
        node {
          id
          faqSection1 {
            text {
              raw
            }
          }
          faqSection2 {
            text {
              raw
            }
          }
          faqSection3 {
            text {
              raw
            }
          }
          faqSection4 {
            text {
              raw
            }
          }
          headline1
          headline2
          headline3
          headline4
        }
      }
    }
  }
`;

function present(data) {
  const seo = {
    title: "Home",
    image: data?.descriptionImage?.fixed?.src,
    description:
      "Nana's Haven in St. Petersburg, FL offers support and services for neglected and abused animals. Learn more about us today.",
  };

  const presentation = {
    seo,
    heroImage: data?.heroImage?.fluid,
    faqs: data?.faqs?.edges[0].node,
  };

  log("[PAGE] presentation", presentation);

  return presentation;
}

export default function Page({ data }) {
  const { faqs, seo, heroImage } = present(data);

  return (
    <PageWrapper seo={seo}>
      <div className="container mx-auto px-8 md:px-16">
        <div className={heroClasses.heroLayout}>
          <div className={heroClasses.heroText}>
            <div className={heroClasses.heroTextLayout}>
              <h1 className={heroClasses.heroTitle}>
                Frequently Asked Questions
              </h1>
              <div className={heroClasses.heroFAQs}>
                <h2 className={heroClasses.heroFAQTitle}>{faqs.headline1}</h2>
                <div className={heroClasses.heroFAQItems}>
                  {faqs.faqSection1.map(({ text }, index) => (
                    <div key={index}>
                      <RichText
                        json={text}
                        theme={{
                          title: {
                            color: siteTheme.heading.primary,
                            fontFamily: siteTheme.font.primary,
                          },
                        }}
                      />
                    </div>
                  ))}
                </div>
                <h2 className={heroClasses.heroFAQTitle}>{faqs.headline2}</h2>
                <div className={heroClasses.heroFAQItems}>
                  {faqs.faqSection2.map(({ text }, index) => (
                    <div key={index}>
                      <RichText
                        json={text}
                        theme={{
                          title: {
                            color: siteTheme.heading.primary,
                            fontFamily: siteTheme.font.primary,
                          },
                        }}
                      />
                    </div>
                  ))}
                </div>
                <h2 className={heroClasses.heroFAQTitle}>{faqs.headline3}</h2>
                <div className={heroClasses.heroFAQItems}>
                  {faqs.faqSection3.map(({ text }, index) => (
                    <div key={index}>
                      <RichText
                        json={text}
                        theme={{
                          title: {
                            color: siteTheme.heading.primary,
                            fontFamily: siteTheme.font.primary,
                          },
                        }}
                      />
                    </div>
                  ))}
                </div>
                <h2 className={heroClasses.heroFAQTitle}>{faqs.headline4}</h2>
                <div className={heroClasses.heroFAQItems}>
                  {faqs.faqSection4.map(({ text }, index) => (
                    <div key={index}>
                      <RichText
                        json={text}
                        theme={{
                          title: {
                            color: siteTheme.heading.primary,
                            fontFamily: siteTheme.font.primary,
                          },
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={heroClasses.heroImage}>
            <GatsbyImage
              key={heroImage.id}
              fluid={heroImage}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
